/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React from 'react';
import ConnectButton from '../components/connectbutton';
import MintButton from '../components/mintbutton';

const Welcome = (props) => {
  return (
    <div id="maincontainer">
      <a id="logo" href="https://www.metahousemafia.io" target="_blank" rel="noopener noreferrer" />
      <ConnectButton />
      <MintButton />
      <div id="mainimage" />
      <div id="img1" />
      <div id="img2" />
      <div id="img3" />
      <div id="footerimg" />
      <div id="footertxt">
        Please make sure you are connected to the right network and the correct address. Please note: Once you make the purchase, you cannot undo this action. We have estimated the gas and added 50000 for the contract to successfully mint your NFT. We recommend that you don&apos;t lower the gas limit.
        <div id="footerimg" />
      </div>
    </div>
  );
};

export default Welcome;
