/* eslint-disable max-len */
/* eslint-disable no-constant-condition */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-alert */
/* eslint-disable no-nested-ternary */
/* eslint-disable radix */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect } from 'react';
import Countdown, { zeroPad } from 'react-countdown';
import { connect } from 'react-redux';
import { ethers } from 'ethers';
// import { useMediaQuery } from 'react-responsive';
import keccak256 from 'keccak256';
import { MerkleTree } from 'merkletreejs';
import axios from 'axios';
import abi from '../files/MetaHouseMafia.json';

const IS_PROD = true;
const CONTRACT_ADDRESS = IS_PROD ? '0x36E1C606fcA18cb7f53856f487E5543DB05057db' : '0x9e49345f0113029CC83f576BEf63606FDA406E93';
const BD_API_URL = 'https://kp3000y91k.execute-api.us-east-1.amazonaws.com';

const WEI_PER_ETH = '1000000000000000000';
const WEI_PER_TOK_EA = '.06';
const WEI_PER_TOK_REG = '.08';
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// test comment

const addrs = [
  '0xA97b249E319bdE12Ef6436D49Ba42A95f58844A2',
  '0xB91998a27dE3CCd58Da2642951208d2944C95020',
  '0x8889b8de7fe57e6dd319282b471c623cd695bab0',
  '0xC0B634033aC166200fF515a36b1b8b48b1e23c59',
  '0x5a6E74197152eAA1E3E4f6164fdc693aBe9b3220',
  '0xA84b386D8961f35F7Bcf2Cd04E1Aa809c549B795',
  '0x034c9C8F0D9775F037d13B0296c901786bBA715d',
  '0x485494D4992B4A1F90dFA322377bd8fE6FC517db',
  '0x0a3910AEf52bd7167407063ee1274e6D0D4a9f75',
  '0xD7DA1C2A0ca6270B166b23352326F75323230CD9',
  '0xEC53E9339C73c056A6Feb1988bc06237643cacA7',
  '0x625B1600072A9CE4Aa8Aa25E01e769b25851b8bc',
  '0x802A0eB7b72D722B39dcbF17B5baE24dF1449005',
  '0x714306dCA11524C5fB3668Ce4502f913d2aC67ea',
  '0x4650bb7511128ABB1d2f3f66Fc1A37A4b2B90019',
  '0xADB0D1A2FA900d8404110d4b8fC60c3033a29060',
  '0x2bA3ef47633De8E0079D57aeB72FfF07A4bB8C2b',
  '0xc816E6f13433B030A83D90252f54cfEa484D118c',
  '0xeE9cfDD3B751b90c10A05C8188D4838D2337E1CB',
  '0xE24170FE1B8A43ed839806de7E2D757B18FdBFAc',
  '0x6C9589C3D7B9588a1E475b922e3C3aeE68861231',
  '0x4d69a3769c879Ba5eb79D6678638d70e2A92A406',
  '0x35c226066e8EF89b04CF7816dd13530f1a221447',
  '0x3040a33a220229414B554673A3b7d9Ff8C900C4b',
  '0x448b26Ae991376c04B38F6C3755570C5eD19485a',
  '0xAF8Bb13Bc3e6117002dB5A56138E17b62Fd1d486',
  '0xde9C4c5Ae51dC9395Fe650B3078FE9780343Efe1',
  '0x2ED04372b6a19E0Dd7CA2690eC15518ab6Dfb729',
  '0x64A32662476449E4070E972c4a433A3ed3c02Afa',
  '0xc543E8a2146DA3a1B54a37B7E5097f3bADED0588',
  '0x2e91b87e06ECD9fd5203308ae8915f615D9310a0',
  '0x49AB2cA305954856F14415F02c5418c9518BcEbb',
  '0x2268244792CCC8974D0A8645B76CF4A8E5eF5Bc9',
  '0x91f2b778A40b4aEd9B2024717D1Ede1B2231A238',
  '0xaF74f02BF570c391457b71c6AAb7CC9A47fF2Fab',
  '0x6FB001E90208ee71AfaC0a53d7bd69DCFA9EBD2D',
  '0x74574F4D12d06ca0aAe5f04c629eF06274C7b8fF',
  '0x3707138916464B36e217A6c2D174bB2151662718',
  '0x0dF6CF2c345C893A3b05b826E795F7771d9c90eA',
  '0xb9601d08B78150A578d83c3975a509D2B5A1709b',
  '0x810b176ccb215257a231E8032D520d9d44D4e1aD',
  '0x0D3fFb8Ee028B628017cbdb4419a12cE5C546B76',
  '0xE60B1a5F4d25E6DF5551Fd77d5A03EC7b0C94a6F',
  '0x27EED4678b1776DDacfD5CC6Fc551E0589D36012',
  '0xed7b5225a2731b141ca1e21a7ed0867c7e191974',
  '0x3691987613A94C789721E5dcc9aBc210BC403137',
  '0x9E3ab75bF5Ec68f1d3aA82CA4c3257eBD1304ABE',
  '0x45912a823a850c8810b931D83Ca6d2419fc6BDd8',
  '0x4C516536B212b56d10B08a3F090983A4A7125041',
  '0xf1Cd3081D3B2864CEE659DC2c3974E5e350A4504',
  '0xab57F9CC695F1DE0b1FE34B5Eb40AD46fD2080fa',
  '0x4D1B82D095dDef515F8e6c8De64380A95434De5d',
  '0x0FC51C81864a8CA14ad6D6E24523c9b1eb152243',
  '0xE9D3c660C6DF458ACf1dD4801963464550bde466',
  '0xc4CBE335ACa6209eB476512E8F235c93c0A5D9f0',
  '0xfe1bc9fb79f35Ab0806a5d528E1A1a4Bc36280cf',
  '0x5529006802a5be007c29261b332C27910F3EfFA7',
  '0xCf4251A2e58C0D5BA0d3C018E55D3d02e33E65F6',
  '0xF02162265754A857984019494f080C1E4b3A30CA',
  '0xBB0aece743ec0fF0D47E81458ADd54F097513F41',
  '0x06f9389074Aaca9F691186c7f9Fe18190F2fb397',
  '0x5da3896946D11759342d5fb88aEEe3ecdD67e262',
  '0xd4C95E2F854e0F7f9a09803dF72A7Da35bC6cDc0',
  '0x3Cea8e6136Dbd8A6B72eb671726570416b1AcBb0',
  '0x9a52c9Be7Fa0FF952f2ce29462857F47734C4Bac',
  '0x3aa8acE7c6C3C8EDCE1111eCaf697B5B10A3F638',
  '0x5c881216a7b67A7c96fcB18930dFdBd1F146A251',
  '0xc6c6523EaFeB1e77b02075100ADf7aa904Ac9D43',
  '0xf12753C550b9bfDe2F5fbf400337Fcf83aA5f547',
  '0x2b26064c9ccb00edcd70c40fb1eec54ea70bc9e0',
  '0x6169c04db3a438c0f76dcbfbff35a240c9b53f30',
  '0x14e9157dAD27CB590aeB4EAab2A2aE7296188641',
  '0x4495E211f9e46B97C75d95fA0863243E7d74B9fc',
  '0x2ee1e0d2479076911770bbd5c60f7563cf9fd955',
  '0x75C27D6B5f1709763D257B2D409c25A6BabbEF41',
  '0x5927A78A04b6057aD4852cB339d6F29B77629641',
  '0xa2Ea752502F4E550a9eDD2Fc29929a3Ba91e5eDC',
  '0x5542908f78274FDC624BaB6b6FADB40b252C5180',
  '0x5b52D3D7551a010D39b7438194EA7faFba56eF7D',
  '0xb48356e5D0efAcddAfEA29Cc5fE791d53cEF0d18',
  '0xd755971de037D4Bf9Bb936F3D90CfB82fb46AC08',
  '0xc441A03b490Ce18E07F2eDaE5cFc83D6231616f8',
  '0x46d7531b83E718D948059497f370a2597E543d3B',
  '0x55BdAF3AF93fC51460149bDA63f054491810BbD7',
  '0x24A367990669b7bfC3f77A41937eecB0D1caFDb2',
  '0x0C5388a68AfDc30144955d6D861EEF3fA0B8D6ea',
  '0x09719f16ef911dbbbEF9FAEF19dB66e8F6D27dac',
  '0x5927A78A04b6057aD4852cB339d6F29B77629641',
  '0x14aeb0Afc304b185E30D95d577Bb66ceF27a48D2',
  '0x5FFc5A5226C9a7E5972771436eE38C9C3E8aaA6c',
  '0x4C84d8bf525fce5Db8f434E45781F085F054b8b3',
  '0x93E10803D8FA9B1dB19de5802364Fb7cB5677595',
  '0x252e4cf231B2FB4Bb8d8e404c9c9Beb1f63FE633',
  '0x4C28f1B62c617d804F22A1638eC039a1f153B289',
  '0xE1f8Eaca944FEfE7B873aeF55639616637172Dc1',
  '0xeABa60507b8f757F35ee4A01988C9766D017331D',
  '0x0C5388a68AfDc30144955d6D861EEF3fA0B8D6ea',
  '0xDB7b09CCFE4BAf36712d43fCEBD064079eb56D6b',
  '0x62341771bB05D4513Cb3153d122903663CDc7243',
  '0x911B527Ca13019f87021BC227771C906f9fFdc8E',
  '0xc516E1EDbd41576E324db211BAF8bb2e28145a72',
  '0x7B267BA01c5b6588e0158754CFef59Aa921bD42f',
  '0xfd030E4EcD6B92B9d9b4F269CA23D1C728Cffed8',
  '0x47A9AaB823a1Fd0501039dbA56C34728eE9E2319',
  '0xCeA64BcA1D14f53423c77cae3755150fcA84644e',
  '0xAe4bDbBe13ED8d51D61B6CB5b0BDaCFaF1cf77a4',
  '0x08A1d338D595EcD96c23D70Df5b48f2e3dDa2C79', '0x6d04C65a54a894C9CD411b729892d9F53a8316b9', '0x381b31eeF503457D7C112F30A26fA07059a49FAF', '0x396E522c8Af2266767c59907104a2BcB3561ce8A',
  '0x4df48bE82Ddc266643eEc94DE034Fe223e9ec1dE', '0x4442D7331431d6Ee88Fbb9DEf98b59d02d71A056', '0x396E522c8Af2266767c59907104a2BcB3561ce8A', '0x6De2b2a833168259fF92d79166E9C5872C87c8a5',
  '0xe8f8A0173F9693009F6c6897e7B6AC9b1392984C', '0x6033b471a9cd07A335D508cc691A4F02c9AF3c68', '0x721b088419EB5CE94af3bcE3264Ee45fC405C6E9', '0x4F5CDCfA3206e85b96e29E5AD8cB4c0C123c04aa',
  '0xBD71e4e138AA7e5914d16b7Df77195442E054ADa', '0xEAe9644979dba846E13895EE115A248feEC7c137', '0x18DBb1A7c3e7E8D65900e46f93ab9b7C6b63364B', '0x08A1d338D595EcD96c23D70Df5b48f2e3dDa2C79',
  '0x60F7253091C56054aC2e3B3EADf6caA18B26140E', '0x6c41759Bc501F311fCfff64ed8bBc73bd43C3f5B', '0xe8f8A0173F9693009F6c6897e7B6AC9b1392984C',
  '0x2Fe0C1d7d623E3fdC5ab682BeDAE6879b899b45f', '0xA690007bBaaD3c458A34Be4aCB1DE42cCdC6Ff56', '0xF59aB6225D49d6Cca9AcbF7b56B7D956B777eca3',
  '0xb4f3392bfcc50ece5567A1609432313a2f15C91a', '0x2B26064C9CCB00EdCD70c40Fb1EeC54ea70bc9E0', '0x258F2f58b0f147650967004D382bACD444CBB1b5',
  '0xeD03137De39aDeE48172075950382e76F82BA58b', '0x2DCEC6F06F63e2f269C9bD96e94B5d9770d7D651', '0x1514F4a4036a0EF92aE614CB602ec3F3B99Ce680',
  '0x2Ed160B0cD0D0f19253534b0Cce3f39EF3C5C0F2', '0x1c4B224f76f63CBd2B2cBD48d1d329ADf8dCc5D3', '0x03Acf84fF86c8BC09478a7AC18a80Ad3250AFB00',
  '0xFB6184d3C1d543F2d5392186bc1e9dfb28ce523d', '0x9d00022BbCF63106cce9DB88edcd4e1Cb37E6F02', '0xD79fa1112f740253609c902B357903d48843c9e7',
  '0x7048f49bCc68Fa286b46Ead825f35080298206F3', '0x08A1d338D595EcD96c23D70Df5b48f2e3dDa2C79', '0x5ee01e23B704c90749BdAeb4abB4787195a7903c',
  '0xF0Af0bc70f679e59D4AD9E877983E90E3Dd47Fb8', '0x2B26064C9CCB00EdCD70c40Fb1EeC54ea70bc9E0', '0x0A3Cb94214613F715eD7e13ddA57Ac5b36D767fF',
  '0xcBA2b3Ac6c72b92955CDA920ec03878B52E41441', '0x0210b46c8B4e9Cc65342C52F31305DFfA6bCB462', '0xe8f8A0173F9693009F6c6897e7B6AC9b1392984C',
  '0x6c41759Bc501F311fCfff64ed8bBc73bd43C3f5B', '0xd1452dc1d6Df8CCE0b284D8F342a3bFC229B8142', '0x28B0eEE7Ed05E20Af2cb7e8321BeD3A517b9B1da', '0x34Bb438861Eb928F42DD6E286A05C5635781089D', '0x3899E0B2299635048b12846Bcc674d16b7a6216A',
  '0xb9AB841AE2730dA4B96cb7Ce7252873A361481e1', '0x620297A8E2672848F54D9d6962112606a383D1F7', '0xd84e791A20Cee28FDC32D90377BfDeCa6779fbd7',
  '0x2Ed160B0cD0D0f19253534b0Cce3f39EF3C5C0F2', '0x1514F4a4036a0EF92aE614CB602ec3F3B99Ce680', '0x2Fe0C1d7d623E3fdC5ab682BeDAE6879b899b45f',
  '0x13fe845f6a09da1DA966Bf8D6C22ecC45cD404Ac', '0x7BC911218AF45144995373090d704082ade8688b', '0x3EF33A6fD41fCE12Be4D9962316C1330cb0a538D',
  '0x95014E5F48F13CdeD306cE3AB98a4ad80417a71C', '3GipxaHFz2A9UY7B3jTt1VemQKW4hGMoof', '0x083C31B19408a4F0CC96b91B0eea6f32751616C2',
  '0x291dC4f7cA3712Dbc4e219e7764C90416a4aA9DB', '0x2D4888499D765d387f9CbC48061b28CDe6bC2601', '0x6957606CC73B3408F30a5Ed976B31158Fc161b60', '0x6E1b6d66f91f25B4Ab9e655E4F2BB06072D84370',
];

const addrs2 = [
  '0x0dEE95537cFE752a35Ceb01CdF865b32b420096E',
  '0x5CA37f0bc52d5968bb17ef04b6F2e896989b4954',
  '0x7f26832FFE67e208752E899F49Aa2d4173D2F493',
  '0xD1D220D9195333741eC8fc49F6028fC7310D0206',
  '0xBa5E7EE28BE2D1CcBBff51E40C86ccff488fc51c',
  '0xCD74C572879875FA063e24be9F80c431Aaa292e5',
  '0x6AAdcA49f3c6887B7f940825961F9e7e00f9509B',
  '0x3fD6B3Cdf5EC32a6875c5b6F355f3c8b97eaBEBf',
  '0xFA631007979D1eF7008aFAC1d61B86451be155a0',
  '0x91cc59AfA13dba355BE3Dc1B7eCE3aea0E624134',
  '0x0dEE486e8f68196a107AF04084443cD11cc759F4',
  '0x5B7eF9E040497e431357aC1969262680FE5bbAaF',
  '0x03D6586E3Ab3C960046cFa78BA08bA0F54cA86b2',
  '0xd13556cDe7963e3839Db20ADC969f7f5a95D703C',
  '0x8513c334DD27F5a29D4FD09F183b841d23125f75',
  '0xCA92b20D50f42742FdbE1557448b81EaC7bE8b7C',
  '0xB6ab74Ea5692712c7059AEae938c166eA0b85e99',
  '0xB1C35559D67F3C3BE3ddF2D8bc24e3d8FFB7A096',
  '0x9857449d42eE571dC1c4Fe52dFd8AF9d20105629',
  '0x23Fff1B4Ba4618ef93367D6E01FD939d6f405EFD',
  '0xdCAF0Fb26E7Eb204014e442675b9A8b4afedCaa6',
  '0xdE3796C0087254f71e3365A9587F83cbe19B274c',
  '0x55eda6fA9Eb55B6678bfaE3aAfcCF8Eb3144caBC',
  '0x96067827F268EEe0Ad355DD9D7DB3246Bd54aE54',
  '0x0984CE3fa689c9004b420e58Ac62ca2253bb4f75',
  '0xEBa1d3fC35Eee6c2b8E6faABF77e4598492B9a86',
  '0x204c14D56Db55a2Ad2c12B69EbeaCF77Da1240F0',
  '0x0CE307a5C86be30d641F9fDFd53135704A5E8b1A',
  '0xe500559c99ba65f2b986F356Ac4eb526B18E1362',
  '0xFa9D5D6Dd8C05Dd1Bd70faD4879BA959C12752b5',
  '0xD3f059e53C9f7a0228F555eeE54956c307d3c0Ea',
  '0x8C9a5CF4D8740bf25af850BF77b6D1133FaeB3D1',
  '0x33a652962c5a687f9474601bdC49f55A41922630',
  '0xE41f6Fe983CEa6B090B8B81585d49F57DF9CC25A',
  '0x3045a8D4d39e6343505f4AbDa36B5fC8E3c03098',
  '0xF7761Bd1e372043638351AeCd175c7EEd3337E9a',
  '0x5b42896A35c34282920008bc394e7C8BA140dd95',
  '0x2aE24FFf56A7332f94aAE9c0c7307A3AD7bDa286',
  '0xE0815097c38a49B326bC41A937BC8Af76C9C700a',
  '0x0F373FA6470756576FE882218F555b56C1d52573',
  '0x4Bde93d9aB723b916dc3D7837b52f44D5E3CA9FC',
  '0x76FdE8Abef81e9ACcA226B6BA2CE5B26d4B06Ef5',
  '0x3ef1566ba8fc35E5F19F27b6315683a644648f7e',
  '0xf81eff0B2CDa3c65aEF70997B6C1eF840fD1b4Be',
  '0xb56bdc576e355323B6f371eCb263A5EcE23B4734',
  '0x731f1Db83840c7d31959f68195f8174c314accE8',
  '0x1d8893a8329572aC98c19bA8b0B1A784e5fCa05b',
  '0xa5F9662DD6B255626562C3Bc979fF6fDab2BC367',
  '0x36e541B6aAC7bA59C8307a2467aE4077E6ff4DbC',
  '0x2BA80c9d587376A991B653835211446Af8296118',
  '0x0aF3307853C246B04E76147D6a32795C61564693',
  '0xd6e4c97BFDA99feae1910fC7d20A31299Ba9dC82',
  '0xD507bFeB4Cb0760160237d1F5f136faeBa065701',
  '0xa090a23c006317Ff0031509892DcCf7821a4Dce5',
  '0x211A35bE2B6f62017cdf82c0Bc92762EaC08eBb2',
  '0xB8cd5D40f54aE4373961b20b532c1473C2b1461A',
  '0xa2e4fc69dEA6605AEc1CD474823e08309d1608cd',
  '0x94Fb1b8B278a067ff756F3c992fA74e771028210',
  '0x7d1C4c695D5e32b12860953D3DDa2C5EBB93029b',
  '0xaC9537d851D0FFE1656d7E82C0F216Fa2fB1096C',
  '0x568F916A1bFa23c0324F524Ab4eF38170f3027a7',
  '0xB257Df90CacFaF6ABC53DBa010203728E79072fb',
  '0x01dC33c526Ff908BaF5c05b72a93777b2115626A',
  '0xD1dad79182c2C20cEC7733Dda67cC30D08bEBaad',
  '0x2C6a4b0c2f2ea1fE5574Fe6897C8b3B91150C58b',
  '0x5dA6972BefA343a8c9FA6B036adF66656BF053eA',
  '0xede3BF9b60b2cF4D8A13bA2B482E16185DEf6DFa',
  '0x5Fd16aE3E0A587a02F376e7b1a642Ec9Cdc2c1bD',
  '0x6EC0C421BD0Cc28777DaECe929AB26A057f43f57',
  '0xc2B16De72164f9E52927F548073734f19158416f',
  '0xD2c6df435a3B6B5996388AB4ab8f6607C31987ff',
  '0x2C0445Ca385230DE2BDF76b00f7b3C7F42eeA6E4',
  '0xbf505b2aC245ABF0197bfcDa55fE45FA112Fe1A3',
  '0x9104fa66BC67eaabAA9F1B54aAdFd1BFF6333935',
  '0xe7e3bcaD553a616eF6Dd2370aF68110740fEB7E4',
  '0x05C513DCcA8aA34C6f3dA23C3C840950Ff067D37',
  '0x97A19D7cc87ADf5223B241D55be1B1084647374E',
  '0xb1fB0F5e64a692f9B6c0a9976927D16db680Cd32',
  '0x2A86725875CE4518BBD8B2a118A79f8Dff078472',
  '0xc3F40EB3054C6DE9ee0Dedb49439b228Fc8572Fa',
  '0x4c2AE11988BADaFe5D58185cB7f6c587d79c05DC',
  '0xe6f376af01e5E9d3122233e9e6792482f5d58927',
  '0xE41D44909181e5b3B77171682F2678748C4bA020',
  '0x49574F1420B20FFF3EC29220EB85F2B636705bE8',
  '0x9c1EdAc1061e2a4ab915CE8A3Cc91E7B5B335Ef2',
  '0x51B2F7DAF28066bCB66D1444c57F1176B66851B3',
  '0x73670DbfA75590670C4544CBe31c7bA9a99Ed9d5',
  '0xc2A2F77579d7b45b4807f74e88Ad37cb506E0E47',
  '0xF71B60F4fA21e5D8a216086af35F44449eaC023B',
  '0x4c53b0361F0f8c29E27ff698BD22281eBF8e0fCC',
  '0xAC0f12146044aEF126b73bC65FC59eE822Ef6A86',
  '0x34217d25a62444484291c5c086587deEc477056F',
  '0x22C9816E31382AC8a960CC3701CBF3b5211CBA01',
  '0xEDC9AEE3664Ef334ADA4d5c7c755275f5bd02Af1',
  '0x7c947f00D49FF629E46D16814F697AD1df3eaad2',
  '0xd5B8EB738d3897Ddf371e13dC30AFacbce038F17',
  '0xf4d8E6fDA68be2f4732b3BD6DE42e5036b72d675',
  '0x1b5D5cA231D990ab26e05f7036D0B6682579Cd59',
  '0x1737697b5BA0B90783E454064116c7cCa4118e0F',
  '0x6335C40021ffF58502416eeEd03D35398fDe36b9',
  '0x94c231888C32dA095C83B38f000d7499937E9034',
  '0x9b34C04e1D670Af079f3eD7f68F63aa151f2cdbD',
  '0xc318F17a23A71F72fEc6FA3fE73Af28b84677aE9',
  '0x396e522c8af2266767c59907104a2bcb3561ce8a',
  '0xBB915183dA8aeE927B856e61fCa446d19cA149f9',
  '0xb06D6CC99F249E12AbAF1441c8BE74d1b616CDF4',
  '0x2105E84a03543268Db2E7e32d94F968522bFc8A2',
  '0xa825BEE8650E94B869Fe19c344cc498B790F200f',
  '0xd0d7467C56eD46700fF1ec1D5F6896A6915b6e17',
  '0x6b02c99c197cF59348e4e0f5A15F29F57bbBacfe',
  '0xC050f1E3C3a29947fcDA831E8EfBF778B9fd07A9', '0x5Eb559Dba841aDafcd8722Ac1c54Dc1713d7E31c', '0x8c69dD91bC04e2D28C8a3656FB6C339D635bEd37', '0x55721cDa09D6BF988805210603a334a56EeF3B52', '0xB70c99035371D5e32d64C7cddBc42E52897267d1',
  '0xa7202c112D66A8F204CE4169864948E301dF6528', '0x2D6c70c9AECc75cf90aa23f742A14d355b576535', '0x9CC8D2931a0E8678BC8fE4a2B78cd92734878744', '0xfbE52B0b70fFcEb9a251276d0575f5C9F7FA5b18', '0xAeb3E5dDD2b2784C71f02483FBC42d324415598d', '0xaEd32156268Aa69894f501D29b30280727C38f4B',
  '0x0523B26ccC2591BE543bE58dA143E0DBc1BFb740', '0x2500eD012d9cfdca23d84d876A1C4E1cd11fB8c6', '0x5AB78B4569AeF5E04AE4BA2c3545dDa3506848Df', '0xe4EB4bC21DFdEDB686544309e58F1Ff37d4AcF21', '0x76c846856715529e5182f94a07276Ae4da5A185A', '0xC456eafEC6d0308CD5A188B7292cff006cc494Bf',
  '0x2Ba2afe1A877531ec225bB71c8Fde776eC164d00', '0x592E9B98737d7F5864EBeff0944F9b64aCEdE51F', '0xd98d45db877c72CC469a30C118eF409cff4535d2', '0x2AC5A0cb5FE369B2184a9E0440bdb29ef67B920d', '0x0F5C5015b25ACbACD747e85C22981102D2AE758B', '0xaFc94251fd81D843395dA477D959174B0cB933C6', '0x355dd372F82EAC0BF5d9E2aC1c780A71Ae46a81b',
  '0x29566917786363154CEEC766C04B7D5e712AF234', '0xDd10Ac515e085D51ACBB27701026197DBF2e57d1', '0xb2aba0412ce7A5FB847fdE8135dC329905644323', '0x0f7Ea2c308F485AA22Bbe28376c1A86B856c8d2a', '0x09b8997CA20A6164FB9447cD74463667c973042B', '0xD64571E79f165BFB78388fDb279b75ded2694205', '0x7bDaf83A735D2Cac4Af6b955f2b56b2329496F81',
  '0x4BB3425ae72b64e9aA98e0eB168c3f539B14dEa3', '0xDFCFfC44162107c39Ea236047862A22198e14005', '0x96C2206c9B408609145794C5f3F40199fB9DC436', '0x33d2a8cC33638d818294F97C669CB31eDDFFa969', '0xD59600BA05693da63a037ad852279Fd75843F206', '0x107780C128Ab992f71b5Bd97C15f3A95A53be399', '0xB25a6B1BE089aEA762F6F1D701f9ca3706d66728', '0x5c3f0C82a505bb878E3A4402Ef2e0E1933E7Ba09',
  '0x46F20A4386A3f3f5A8b36f350b8518327CD587ea', '0x787E371945fAD4A346E305d4D7C3375070f906E3', '0x5f98A5c6eE6c387692b1aF27Ec3B118053135d81', '0xA9C52C15b5915eaDC584FC07D25dE495106A3aD9', '0xE3F0F128a2D535B810fa98256e4E47FAFd5422e8', '0x92A15d7801CBe74743bA74D762816033F6908C77', '0xa6b8197038cC432BC55975dCa18907C77fee5878', '0xA55dbb4D1f7854a1f948755CB746AA724aF72ccA',
  '0x92206D0D7A8FcEFc2e026Fc4Fe7a5977A6b41CDB', '0xe90Ad20156Cee3B4865f9A22902890c59EFf40ba', '0x531876DA8c609425EF15f55C6714029E848c29AE', '0x09c6267410489c2C524BF95f5b65987E950f1024', '0x271CE9706cf9DAA076f8268185cbc1Ad5b5578D2', '0x465945Fa5D23C9437D22F1A211f387A527B09b49', '0xc442C2c31E056f1b864944C90A17B8Ed23bbfcF6', '0xea44E6Ab1891E501A5e151c827B972Cf88c14bBd',
  '0x91308f85Ba2c82e354235Eb919Db9149F1eCA720', '0xFBe3DB49f746683058D1F62680c92a60F8477412', '0x9A0E0D65b1b122A2500e7c2E58e5511d0291c72E', '0xC9BbEfc047D0FDB01eE45e04BF041085d62Aa17b', '0x795e3b83baf4490ffef865958940bc0ed89f5cc1', '0x6152494a175711f100c185D1B0BD3A141A7A5166',
  '0xe722d51cdbf4dd9e3536d80c8557c95df0d726bf', '0x9462ec430516fF48d8c190E88B482f2A872dDe84', '0x2cBF9443A42A19B82c6C613c349686a15F913EB5', '0xC79d0f818C3AEca4784F683FA448929046319130', '0x4f2d15e940eac9f40c80375c0bc0ee7e625e85ad', '0x7C59EEf683e22AD090189d28496aAFE6b368CB48',
  '0xF2cb05b0Ae3923c3b6Ac786542DdC3722Eef8c6D', '0xB5856EA38715fEd9660a6B1F1459Be4356301a13', '0x72Cd3F3b32b2bE621F71378b76ba0ef4eC9f3D99', '0xB905D026F7b8A5C77D5Aee9384ad3A4530941fD7', '0xD195AaB7A15A16103E7c5CDc78F764d176E89dD1', '0x395B4b2004099044cA00eB41E1a7A5B5553cDE95',
  '0x0F4485C59b093c51f510372e5b025Cd4F5850CD0', '0x3B82e815ED44E03c73F84f51cf37507C8882AA89', '0x1E0240122f3157dD6976F4C5cf97a619A316Ed28', '0x86698E6742607162639B0b89006361033215FC2a', '0xb8BB6FCdB7B0B3240B4BB385A763B3B3207A8286', '0xe599aA2bfBDF6e0Bc042f83c80F16aA66Bfa04bF',
  '0xE7f4525Ad0aeD189952247D0e45b9825e8Adf3E3', '0xd6b2E226E16591E7Bf7eeD7889cFfD52BbAeb83f', '0x7D133c97640F7C7cc6F0d9Df856940FF8016468b', '0x5687feAB4aAb056cCa0878895b56BfB02424525b', '0xD191fa79bEa7e11f6ce37bd74AcEbd408F66D0C3', '0x5133F0ddDc52d655A2a5Ca662d97641eB241D706',
  '0xCF103c4Da17b916cfAD1251E3831f23a9D1986c8', '0x1e6a6E8D46812C3165853Bd7B742a6D37C5A154c', '0x43Ab6D64fF9DF99c6aeB9D010a5D1b9B90E23377', '0xeCa552F150E722FDFb0Df31b4311B1CD4A115123', '0x509eBa604eCA93a7C0c1188E32d39B3977a84538', '0x607270Ed2b28dbae3926531A59a8A6A43d579be7',
  '0xdc6aBE2F60d5214095221A90e5F2eC1655C4502B', '0x20F859416cab886e356a0c13a1A8b88360150eF1', '0xd8Abc9916Acf73357794Ee3c0B47FB8b81993e92', '0x77aEf3F47dee601a1aBc30440563C8C06E72Eb4b', '0x354F7a81a7AE715FE9a9bB8057C76984Dfc1802D', '0xAB9a4Fff9FE85Ff078C2EB394ebca8010956A7cB',
  '0xF9f8FC6dB6E43796CE20C0CCa4832107c0b2e5Ad', '0x6872930ecFB41e6A378e60F0cC10e7A9851F86b0', '0x3976EBD5D35921a1f238824Da3BBeE0B154daA35', '0x0A8d4320a473E36c0f41bb51327A3Fe6B1f70962', '0xfc0C59E20737d3CB8B4D2195261402488B6b175B', '0x0e5997f10F3bA2391a2464244E41f4E093a0AF9b',
  '0x91EB16e95d024Cae75F8cFc29a6e9c906170F427', '0x40517c1220A966D34f28b87C64021865C3dA2756', '0x41221deb43D5f59Ef96de5D19693E8ccafDA0819', '0x80E8266CE194cD4EB9523d1BeE6852d220Df13Ba', '0x1B7871f293fB96cCF8711Fb233A63F64B3811937', '0xbb95A776042D251fdcb1CFcd19247245453cBb98',
  '0xC0f1dB5DebE8a05F06c047aFc3fEB56cDddAd4A7', '0xcA15C0a97A3E88A1B7aaCeBC080c6fe3CA9B5F71', '0xd1EfDc5DCA5F5FF30028091d9A0c4f00aA920D2F', '0x7777a34be5ea0d8d0b87a430F00878d8507cAE9f', '0xADA5A171151803EA5E6210361bf291B9Cb97312f', '0x15A02832fd290fc49DfFf226A23f8f41d4Ccad6a', '0x1035f845dC197e7CE7A5c58da005683DB663Ab0A', '0x5723C94Cd31B68D769519C3431E1700a744df4b9', '0x2695463A1f83aF797Ca0Af5d6d13FF494C7E141e',
  '0x23cE7eBB301131D43F770Aa755405d6C7CBB8DdC', '0x1eB84953Cf33180c3B9602F474e50666Cdc4fa08', '0xDE913C1d7f85AD30Da20286912d45EF7Ca88d736', '0xCC9a09DFc8c86CecCfA5a9EFe86f0169428695f7', '0x1D5BD23319c0e9bF8A57Dadc2b0e7FBC94c8F751', '0x038d552E4F4cE2Cdc1ABF541eFcD2A74e8616D3B',
  '0xC1B4972d4c968889dA6B731CEDE6fFaBA2AD67B2', '0xadaa435703402AebAc55F0cF98d39F174e0053a1', '0xAe6f80c90106887686b46E974e95B6a7224f6Bd1', '0x1686694fAdB00f686E2Eb627FD2986e3bEa1dEee', '0x1521432Fa62d61f1220FD5696201F7c761C0232F', '0xe6Ead25C9Bb527FDBB8B5605618F699E6f1dE4bf', '0x6F7EcE67b5620ab018e83BbCF4F49Cd2836B51C1',
  '0x338593CbD52E1De83Bc488E062E73BB51402BE11', '0xb7c68969100e924abc26b85c63d5EF74E02DD4EF', '0xa6eFa3d516e51aE615F3B717f84D9135FE787259', '0xDF8b8788D8Be7f3b339A75AE587Dd354fe823C63', '0x2227001C4795e30FE65709D39673a1B7BC4c670B', '0x483EA535D8f6a1cd94eA58f35B43e4dAF810BEC8', '0x13C6B9457a8365DcaF3cF722EfC42398ef5cBeFe', '0xDdD6001df7b445e8E24938038D3760a2dBedB162', '0xA17b7A649F8e9D228A7125F24dF19404De678284',
  '0xb437e15d84Ee8c58Bd4f9Bd62bCFEd338A83F686', '0x449B9Cf93823D0b8683F1d5A6f0b5502bbdBAb39', '0x4C1E6A483d37f887D36aeD31E1A98347e685A0D4', '0xF7e6dfC20D6ed80eccf1B4464B1cB393d2955e1e', '0xDc2FDfb17751AF4464DCb186e4042Ef2931F4515', '0xbb03a1D9f5f615487C223e2c11882684b1E44492', '0x700b92CbA350de814fF912707F85eBFBa9A92185', '0x1152D1C5eD804a0149CEbC8E71D068af2b000Bb5', '0x6e241D63AD0a7927FD61ba4554e413403B507517',
  '0x06b7A304164AceA5AE7f8f3e1F5B2229E44F3EbC', '0x430c515E6395Bf007EC901078079C1D0EcA0d242', '0x46628cc26B7eDAB4F4EDC18e0712710E2bec9Bce', '0x108035F9aDf2499c36292a81011312Fb22589027', '0x4195F773d160CB9C4aeE03c4FEfa85cDc0B71815', '0xe0059C523f0882D3430b4c1F6AA593F8FF7f6534', '0x406Ff6BB69259b4E840d1d00EEB6a8808ABc001c', '0x31bB5ebD77c38695a42649cD19a989Ee89E96F5c', '0x14188A2cAEa507D88B5015cfc8a445f153bd07e3',
  '0x3C4DfAf7b207002A68Ec41846379D8Fb7Af99D78', '0xEB89a540f755D10AD3f8CAa3bddF976F306a3494', '0x50375c7646Cf84b59a02ee81DC26B28DD79fD963', '0x4ce0061498B983FfeB356AB37f0c34C54a5631D5', '0x5bFBd24bBE2971Fa9d577451dBf9780a3F765681', '0xB66b272Fd7017b5473b684C2390fe23036af1748', '0x7abd3Ebe20E1e6B4396235c2AB95C0c35aA9705a', '0x6F78a50093D90329Be46848AB0f92Ba6EeAA8Fb9', '0x76F91dF737E1AfBe28D0E1b74649F9E16a2c3382',
  '0xD069a6BeEBf12D4a24807da9ED0393f0357D0589', '0x7eBba95e7017041C5015edcF1c131Ae55046FD20', '0x85e8df59799A6cE10fB2Ee1833F38Da90B8ded92', '0x8E669Bab785a3248b55F3850A6865b309F04ed04', '0x619E9822a54AbdCd86504247924aF8703455bdcc', '0x7FC6bb05ffaD5936D08F097FAb13d2eF2Ff8D75C', '0x09D5AD53E51D1ff2b8D0449A670cf796A991eef4', '0x59edCdd9Af6d8f62f80C3694D064cA4f3fE302D6', '0xf6B8Db68C207c5F29A8b39Cd72F84887974f16b2',
  '0x309Ec9d0f05d74EA2d4e9850D7b70539a1D1a769', '0xE3F071bC58871a49b75e2B62E3c7D2321B87Db36', '0x92b77E7E786D50ed2F6bF65A911A1369b75ec807', '0xf91E9c48c3Aa77D5dAa09E62931A6c644f0D14c9', '0xE32A35945fB2894Dab35a87E1116fB4087eDD3Dd', '0x85a18400561Ae07b950CE765623614315AD08624', '0x912A9FcDA16507ba386Fc82CFa1147A7896319f5', '0xaa65892eb4Da02319675c902DD3264608003418B',
  '0x4b9529820b295b177A7405ba7997eCD24FA39cA0', '0x7666c5387796FE01267b4F3c722cc53E44f37a6F', '0xC1b0A06A121C1563ABf4Dc74A5e5AE484771f103', '0xa93fC0cB12e3D19463ed3c558b5A456ae381beA8', '0xCd14e1f899f08Bb1Df3761915dCd6259394fcBe2', '0x31DB602D9D187e812E4FC78549A5eD3A69844f21', 'Oxe044A9AD2C2a34838173593b28c8BaFA33DB2E13', '0xBB7633D5f7BFC0f7846f1A32d98A1109a2407553', '0x56B34DF618D107A2B951e0Fa1F4B6Dc8D642232c',
  '0x0f9CBa7Cca78B01e1a81858d78b1C2de250DFAcD', '0x3077EC8e1bf1E4Fb2d2B61551Ee47dD689E1706a', '0x665Dca3E7B9507427a37d727Eb42ef99E28D2D2b', '0x3f9B57c98EEDCaa347BF8BA0F739AFa3cb524D6d', '0x0553bab8d0EaabD40CC65ac03c5a866f8114195F', '0xEE85d61C88f42aEFE5cBb608555eB7282Ff15d20', '0xBf1f666138FD39373DA8ed4BA4d0eDce4665D5dE', '0xc01D9d21195F3954f10A2590718889922B69fA6C', '0x34Aa8012210137C6109FB644117124265479eCDF',
  '0x763a3f0eCaBe1427b5b5b5b794608D1D1461E739', '0x2dcec6f06f63e2f269c9bd96e94b5d9770d7d651', '0xB8f69D237862338Da0A09445fCE411B77f8f18Af', '0xec074A3B55Ec65F58C6925581a270d52E1cbd280', '0x12c89369d45043012e0514d90198044B9696fE4C', '0x013c94533ce291CC95571Fbb5731f82dAaEc58aB', '0xB3F4420590c683a3B84FfC3C2852DdadC45aA0df', '0x6ebA999b820DC0eE6D6D2c1909dC08Fd7A6915d9', '0xdEcf0BE250d14682DB9445632F21375360B89D7E', '0xac57fF08Bb661E23F288168319Df4255fB394839',
  '0x7c0832b6c676CBEb85efA7fb6FB8f0F16ded0Ee2', '0xbd055794BD07Ac03c8Da13ec91Cd12e1C56E48f9', '0xb2cd404F5C1E7CA80D8BB8e64C9C30952bF1A969', '0xc464ee3B3A5FB61235c63949F8DF540bB1470F39', '0xcA641C503Ee738eaE03d736108049618Cf11d99f', '0x0695d92F55dEFaAE6A31DE213ba5a0da34533Dce', '0x5b8116d6b2f9ec14a599430b6541a37429a161c3', '0xB08Ee35d1B0BE9EB55731C05e27039FDDd009Ac2', '0xD639FF68E7360f8F5fDC895F6cE1f52ADC687DA9', '0xd6dC591e187F893EACa99085b852ff20ECB400aB',
  '0xC6F2171b0E6712b3AA8203C3F2509F899340797C', '0xC0E5C7946F0404e281fF4FDea1fE4F76fE4f8683', '0xe018DD8c8766e0430bD5ef8810581e589Cd5360d', '0xb57664ff4AcE63E947Bee7Bc7d5E2d8742773d47', '0x1bA4754d5A2Bb04430c46F0ed7D9e343c0f6B893', '0x352Fcd18250691E1Fb56B933104F90A7DFe1B686', '0x95207A4E487EB943d78100E028C877Cf3E85F56f', '0xbD987Bc940FaCaC7b2bCCa0048fd5939A92329D9', '0x046F34Ad5b705D3f76A8DD969744eB135419d30C', '0x1F7bC5b09DAd8c66CA9d36f5c13476e5757A56D6', '0xF329761C3f735fFd86f4Bee62f7659B60423bF36',
  '0xb96af4e46b9154C784238E747572A80e3c99831c', '0x5e30805129c13470f86c6b6063d07ff2a6e5f2a6', '0x03F907f3184757fE8aB0B50aBEf574517D41CfD3', '0xc9a1E1c01C7816513944d965841197Ed26AfF509', '0x082A2F9590150A28c9A200d1fD90157969AaEcB2', '0xb599810474b362fD207fDedcb0a544d34BF45Dd8', '0xC45c2cAaB6463f4e1A3d17C49393D4BFEc36F604', '0xf9e6cf0db25f50464d2ac94914096792D51E3d01', '0x135242892f496f966993fADbc019A394BB02aaD8', '0xE0f409bBeD8407ac3ACFD274DA8716DD58F4adED', '0xCb2a3a7Ef698838C110975b75dE344fEdEB769F9', '0x47CD660951Eb44Ee5C09F2f350b18dd6182fF107',
  '0x540528cF83f1566ce1Ca43f6c302B963D0eC078A', '0x485AC120C24f53B1d905852643815dE2Eed68AeC', '0x2238c3E787A1b3f1a293343fa47F3FAb70D18aFB', '0xd636D7d748a07333B14c2025650Ead38D3b1AB23', '0xC49a346EdB904F04AC0501132775E20AD086edb5', '0xeD0425974E4C1FCe59FD7bade41075fbC9e2F339', '0x99eBBfAF655A7C91e5531306a25a1B48E975Cb3e', '0xF39bb6353D4b5BEe8Bc7058BFc23d4AF7Bee9572', '0xD26Ba1f0CC158EEE2A513D810148842a97411BFB', '0xCfa73c03e92915021c7005d54350B3923f35d169', '0x750600CF80057A8Adf778B3c1b5657eD5c61DDfa', '0x94a93269E35094809bFF1D461C5ca66a690a4ebD',
  '0x2E97AEE4CAB3709Daf15d052aF4b1605fd597319', '0x5aAA36Be51197180CAA75d0b60F987215fCA8c32', '0x258F2f58b0fl47650967004D382bACD44CBB1b5', '0x12a5b9987eD8fa537718BD26F7Dc0Acb391bbcF4', '0x238631Bb6D10f5902D9c152115248910ad006271', '0xE8385Cb06D9CCdffd3ed67443d711b2774da2E8A', '0x9C97786Bae8ac38173875d2BEDA585f5d68D593E', '0x2DA7c73493c886fc6F76Db5771728106A4fb27E7', '0x785430C5d526E91ACf2423Bc75e75134A49131e5', '0x3e3042a94645391a1F5E1EDD6BC3654425D89455', '0x0C14d87B7949dEBCB5cE54fD7eA825a4F9F69ad3', '0xa813df319eBf17e862ccFd10De7cECAbcF33D989',
  '0x4E555D3d0B953299fD8647C82914b106b80E908f', '0xD84020e47bD4CB1CBD716dAc2403cC864e455Fd8', '0xAF742D022D4830C4904D2238A93A94513603e1C9', '0x175F4F06968434C1d6974645B386E805ad9555FE', '0x700dEE37cDBC09eCff5E59eAB6282c214C56aA1a', '0x2a5027957E1444A16dF4D54ea509B5Df4a7A076c', '0x51E6e0C9A8343A8A74Ea3fb6CAE703E476419491', '0xbe1B1E841A13018A109C803736f71d25b49BFEe6', '0x27FCA5E0D08C9A9bE8610A1747ce35A6f43c6fc9', '0x1bBc5c349757Ae7d14B7c0f3b3EbF869F00fAfd7', '0x67142a45724c20a97Fed9A796e43e640FA27db38', '0xE6581eF7696115D09129ACE570423dC02A1De6b6',
  '0xb6D2c4201C8Cff7e5F914746D160d87Ddf3Fc3d1', '0xb203D3bb4a045021Bf1A89329A9E3997D4C3f4A9', '0xdAc8Bc3EcB3057f94880699013E30dDF46457104', '0xB6E8c2A637d84a8E335aC1D03c039D0a0b8d80b8', '0xAde0F213181844D10295e8Fcc79ced4F14422ce0', '0x09b31cdFeA3d8B2e3AF538481a3d9e1Ad0Bdcd04', '0x415E87066f443Bf78D91c0dECbCFeb47c3BF8e31', '0xc8523CE8C732079b5C43D93125ff63d2aE60215e', '0xA98Ba81db710083c2082B4F57EbBB6E0D347E54a', '0xB006B55273203a1D78067321f6E8D9690d020F15', '0x626280675D4C7b7E302A39e469B8836a1B2d0AEC', '0x128E446bD6df152A720Ca05760C9A106021b08Bf',
  '0x4D80E516f53832a4c2F5c129895Ff431021f819c', '0xa2CFCd0682c604658F334990a53dbC174627537a', '0x5D21116889d86151fE189C84AC77f1fe05979826', '0xD184C3bD314c19452427e114C7f9D614a79C3199', '0x49b01C737Ff1d2211c6D54E083096170B6925db9', '0xE66ABA3822286061A04558EBf583F125fc76c305', '0x8F73893F146e908f56CFA524a84eAF16D5190936', '0xf60f9C325409E1581053b594E1D38542908e8907', '0xC3023FF1771394c8A37f6D75fb0dc152112E74Ab', '0x4B53DE9813882981f32E1b0Eb7b944bD7F49B8E7', '0xC33324cd6694E2f85b6eB2DB6c2122a175030D5D', '0x15A0dd4EBd07d72603BD0967AEd39fCd38D1e348',
  '0xEfe1815fb0731A31cF2ECE17151533a42F7f14c4', '0x8BfAb8bA726D43008a912709387325EAa3E7cf49', '0x0a0485509DB34984c95F07fBC8E2f647adB06320', '0xbA97d2146819Fc106C60311d6b0c97b9915aC5e2', '0x2EC7d38fB05b7bA1F29c3C0D47F0A239FD7f869C', '0x9B7846802C0593FCF195022a0f8bFfF4ac878120', '0x3719a9aa9Fd4CAd473047Ebc06611aA54b60ff72', '0xEd2A70B5F29ce2D0BBe8d65CD3f90D0f86dFeB76', '0xa36Eaa6938B7a0c15E7A621B676805626292cbd4', '0x230831dad850e23743b92dD833ce8181db5E6Be8', '0xE68462150612e39EF3E0feFDa8D19b2E32b09454', '0x829b194f592a4301FdEb8bb29f01B24C88c22D55',
  '0x4043e750312A267972b28FE5Fe053AF6769531cC', '0xd9feA70B6aEc977893b0E0dE4a8D8cC023EaCaab', '0x48E82FC7629DEA5C877b9650d017eFEa2431EaF8', '0x2D5dcBd85E583A5F35964b6bef9C680E7918f88C', '0x6A7d4d754e35A95756783A3dBa228AEa03E359c5', '0xA3010D4B1Ba8fccBd71eBf38185913072a03b1AD', '0x94642Aa04eFbc9585fc57A9F258C3a1a4C44537B', '0xdbEE2e4a7D906D3F7B8210036406c7B575D85eC4', '0xD2403e4AAb60aB0824921cE90EBe5d4314399039', '0x5F049aD62feCd95c54B4018bD45223FF24c7443f', '0x0EB3C68865BC22c85AceC01D09af1877767e75EF', '0xcc6475C41501515a141ADDef27a4Bf8C0D83F3B9',
  '0x11f5844Aa5B9CA2E8c6d958f06d0AA2dF05b0B25', '0x9d322eDD2d49edE014579Cdd92cDC50842af2886', '0x6822C78d021aAff87c14a48e28bEf54A4B5E2D51', '0x3BDEBD0d42C87bcA29ea188A3Ead63A666BB012E', '0x6ab3b4272d9be3dCdAB28Bac91ca39C317089aB7', '0xd5c2F8aD63f107A1d106fd11437B09fEaA6bDDD2', '0x2aE49F94b5762dEc72549A6B2846ad0F27894113', '0xD13a3F831F3eD96F787ceE25db24571B052ddC44', '0xa5D2c06D6f3d894293b1b77c3917b3736A1456aB', '0xDB11C01a82A45cC5F18f989E2947b1B78b4581C0', '0xeABa60507b8f757F35ee4A01988C9766D017331D', '0x0B201Bd19496B2bF12bB1814407eE1A5598E2b3e',
  '0x7977824E8948C5DF9BB21D1F2F438f98D87ed34C', '0xe8943b2B79fAB8FbDE0F129346F5a9aEaFFa518F', '0x3E8569B6Aef62b61242caed6F4351CF2e3b17787', '0xD471966412D670E6425c5c9137e01a3F2760fD99', '0x721b088419eb5ce94af3bce3264ee45fc405c6e9', '0xe20B42A410Af71332BD755663fEe41aA5241A984', '0x45EB024feC9AFada92D0b146e02880A01166B423', '0x7D2a4314A41D20Eb22272b9a29DDa5ce4f3e74d4', '0xE66c66f704217b43544d5AA7765C305e37F623C9', '0xF4399F86E66b82b003b8fFbc06eD855c998e62Ac', '0xDB557eE6FeA4116566a77434b2ca9A1f4Fa88e17', '0x8bd29A19D53a12733DD9628e1D531bbF87f7f502',
  '0x53cadB317a4Eb7C6b72114abE9cB1E1E0bfa5384', '0x71dfC01afA078C972C9DC03cf79Fea30f8C7FD5F', '0xF403392550A1e84117b1332663BCFA9Bc89Bfda5', '0x8b7fc77b8c499193dA4cFDc92eD45c87DAaE2F56', '0x010c2DE212De46fca0798446Fc65B1F82429A697', '0xC092880856c9BAca992584C71EA2C92f735D2dB4', '0xc39de7aBB681BB36b34057F6075afe0F1514E87d', '0x84Fa1D08f19C17873F2657b3203E8c12C0E7385a', '0xd8D8c640E982a69E4E948359613EfE2863929027', '0x61c36143867fdaa56fdd94ba8d27dcc80753a159', '0x465B0280b95F0729E836c3B8f99E181CdBa3aa3B', '0x04ad155C906e8825f661e065fCE401a025A7618B',
  '0x2302f8DAa5c331a1A8c3e19DC9e2452FF808ACbF', '0x9c3C8d042Dd1cA6f2Ebd1aF7c7903f4086fB957C', '0xc2b46b9E696E70cDd940726679c7908f3AFC629f', '0x8Cc13a9fB2a86aF08E9ffD913dBcE112b8e6f3dc', '0x001f320e34dAfC66792769903f49402fE1A3ADC7', '0xD2D0bF9f1dE141fce5DEe2d5abB2ae2491D44583', '0x2430dAa9347d58E95CF01b784aFbdC88f19e5ca5', '0xB574d4B4789AA08C52c8b44C9425A38AdE7A3789', '0x0B29a440781a4Ca3F06B6E0323b981e1157dF02f', '0xf2D0b26443426f06D4a81d88c4AAC5297ff7274C', '0xc1469EfeA073245bBbf7d93600Ba7e027b8C88Af', '0xd09C009B60E114dA7dd178368b13D16326E1dC8d',
  '0x687f7Df3f73d6815540f389b9b2092f49fBa108B', '0xe98bAb964907222Ee25C070F3E57a4aa59635730', '0x9F95b549338F99AEaA3Ce14BCAAC194B2Db9FCf9', '0x84212bE7E42614FDfFb868703c8a70F0a31D27A9', '0x5006ea8ab1346F5d441E3eFA74cd6110D380C644', '0xB66c21EfEcf9A3389B3148b5be5Ea4d01e667B96', '0x0097c4ad432C54841691527e5918353B2494EfCA', '0x35c3c4dAC30b310321bbbdFdC2705634DBa0ad11', '0x5e26567E314ecee18781e1C1DF560c6382e00A9d', '0xADDDE3086B2468417c0A17e4A0DD66EBeD3196ce', '0x700931749E0159cc561a882EDc230aaC94975145', '0xA2A705aa2AE529235e4662CbEfb0A1e70C09dE17',
  '0xeF7877d1C3f7e56f2b521ef64645BcaB37a7b654', '0xC03559F5679DbE3B02c9BB54eA2De4B7f1ecA346', '0x8A32265e8AD4Af7C07055Adb8B32eFEa5dF026ab', '0x5f27Ea33c1EC0Ccb1fDB97c37616C7845CD97062', '0xf7660f3Bc0E63F0c733c332F8E36d1Cf8EE52e9D', '0x14D8004fD26f0F21b32eF69402B6815aDd2D4904', '0xe8f8A0173F9693009F6c6897e7B6AC9b1392984C', '0x5BCD8d33B0D5b96edc6C02B08c82F09e29f14EA4', '0x98a61A8c3000793baAD7C3794dde755B90e75d3b', '0x57217eA9D49dcF7Ff1DA58f00f737758409370f7', '0x866E58500f3C005d8e732af06adf9a8F5E33b86c', '0x86492226eC7e61c80D792ddD1F84084b2be247b7',
  '0x6EcE38AC11C71ecfd5Bc35430cB69B6C8A790EC7', '0xB62F1541d881C2449746E0855B7C6AE84f69E6f0', '0x7023b127a1586aA13F04FD0e8ca6747003759763', '0x35d465D2eaE83FBb150839E2D087d933d232d4Bc', '0x438BEe5bB90796bb406BEDdd5eAD5B575Ae4277C', '0x38B9186e1565Fc76b6920f1E7499DDB83F7c535c', '0x15a3079657a3eB80d1b9C45C80aDe46CBdbF8423', '0xEb6b53b36C1f32b5701F73f3D359BE0900ABF7d4', '0xf77b60d1fE1A50EE967dD3613E058A1d6DAd781E', '0xEeC343ae92eC115d195f9aF602F01f9D6AC7142c', '0xdD01cd6025955f1B6B81F80626acD1b181623a01', '0x5C4b38636ba0089fff37eB3a6c922Ca857354A59', '0xB2C29Ca9C3fe30736Be507E6Ed319a9B09166FED', '0x61eef709AF3AAEdBAcB0221BBf84E03AF74EbA1E',
  '0x62e8896b06cFA649c32b81aC7617F8e58a9fb2EE', '0x798FeD33F265EEC03E54DC8CeF2A834F6d68070E', '0x78924D9bAa4E29f107F1D67B99e1F5Cb224aAE5A', '0x25A937c920105a6774e8FeD65cC9d2FA803147A2', '0x42Fde16Fb86d7B200FcfeBE6ee47576A831081dC', '0xD6a37CB729dF21eD527AF87EC7CB531F7aD5509a', '0xa9aC26F5F7cA2C1d12c7d02Ca3D4AF0a6543b650', '0x94984D4019e4bC53C07Ad273A0046A234957F659', '0xCe618b46e1bFB6b0601b121b84CFb29a055a96C8', '0x5d44E943909Bdfd2BD38c2a2Fbd8f7e8256Dc42d', '0x815537cC9c4E54F232389E71C6413FEF905515C9', '0xb2540899fb68866cc7C85c7926e5eA1fcf6e2f23', '0x767F880629C613eedDa78b61f83F43E46432f380', '0xa6D2bb016B1F4ed5698DbE035af9b9499702C653',
  '0x7bdC78F80Ce6634f79cBc8de338976B7c5575D44', '0x36F3063183ca5345aFD6398178766f1967f682A0', '0xebF88E36110e2d628D6ac4A9F1C3dE09bC710689', '0x0bd7352d78Bcd99B91B3588540477268E42b8F5B', '0x7A85fFd51aABfb771468431aCc7E9a386102F4fD', '0x8176A7A8FBb4A59Add441f402c2340F07eeDB1E3', '0x7F37Cb4E1fF71193175B11c16FaA7d97aa851876', '0x6957606cc73b3408f30a5ed976b31158fc161b60', '0x9d00022BbCF63106cce9DB88edcd 4e1Cb37E6F02', '0x759eB8890f6A627931387a22a256DC1399768816', '0x56F8644225b1ba05118Fd25d410Da63bdFf9e6FE', '0xf128423f3b85c7e8D13fA02363a2d8E28c3f0ec8', '0x6080aAe18420fD1cA7075B3848b806951516c6a4', '0xD0912Ce37C14c636CdE507c5B3216da8d29c4E41',
  '0xf7FE024F8c4069F4003e3f452ebB6dA04FBE656C', '0x5659a24b86398f03aa36d6358868ebdad1a5a2b2', '0xa690007bbaad3c458a34be4acb1de42ccdc6ff56', '0x35dAd64651df3A3A668f81672962a2134b611556', '0xcC23Baf87542838C1B06c48b2aac6Ccdd7EeE59e', '0xa6e7CBb207a3CC9fC8e54853Ac83862f02657d1C', '0x5f5914dBB7e805fDaf2Ca776d1de016827331Ce1', '0xB1C410C19Ab6e635DFE5458cE70e922c791a68b9', '0x98734D06E2cb0B1C03bf2D8423e2F5f4D5d83721', '0x6bB455c0047482a263F536aB96a62add0AA20eED', '0xe5094F5d1d0Ef706A7D577c7250082E245b50e6f', '0x787e6704B97C28e41D980859a67Ca580bC78E264', '0x32C02f283Ece82989c00136E1CA48880955b8e3D', '0x2c3E9Bc4134762878f1F2cD4EA4F585EE9BaCe99',
  '0x17ed372F23d1868F7a97a043b4b9cF9a42B1Acfa', '0x9d9c340C291549529b212CE19bB7b5B6368bE8Eb', '0x379390458cf2aAB6696E444010650f9dcd5CD985', '0x60DB1fAdfFd0F9182Fb1CB5eB731cfa3Dad72276', '0xa960904a0F1779Fdc47253CEC36f6985c499d115', '0xC68f58075817353d69D490196cf5AA06CaA978b3', '0x525Aa7c2B12186D7327A6708c877b8ed9376ba53', '0x9bB143F13f7A2b23C5fdACe7594456CA0cd2251A', '0x38670F5f39CfFdF99FdBC50B262Ad0C6c64210E1', '0x1d105134ce2A3e9be24cED130f49ad2806e2Ceab', '0x4931dB504032b6c1226fa3660c38C7235b8EAdc8', '0xE7B5Bd25eC6025726Fbc1bAffC3B91D0Cc142c42', '0xEa85B625fDa5fD05394eFc191E27AaC06d85cF42', '0x9104fa66BC67eaabAA9F1B54aAdFd1BFF6333935',
  '0xef6a2E8726aF6853Ca278d6D8559A9B1A06eB4E8', '0xD6602dc0640FA9654320229e81323c29C73cfFB3', '0x22Bc119DA78B7EB7C14c7FaD98A81608B06C8b71', '0x30486F7C1bd088A81F25A8075ABEEbb860F14a24', '0x91760f1DF754944eB6f70E3a5d03BbdCe7457c33', '0x02D3E09e03396a4a3a91061DB7a2098Eea330eB8', '0xD2Ad893dCd0D58a60CcB3d78F8c44E29c3CB1403', '0xE3C0Ab91807EBCf797f6EC963e19143C07818759', '0xc38a242eB38a56d90bB7307Fe3fEa6F671B6B708', '0x818dadc97D58dF231660C4868924E42522E11Ad9', '0x3A6c5055cE62b75D539fE060F84F63a2396b1913', '0xa5409ec958C83C3f309868babACA7c86DCB077c1', '0xEF9a35B4c7644bbf3493d7e45efcb1A295Bf33D4', '0xe03ef9b210D463DF0D4cdE21238a25562e5017fE',
  '0xe32b9E4D2738038b49fc96e847f3e5Bc84D0891A', '0x6aa4bDeA2788bF457c6F10beaa2BC18fF19b2bb9', '0xCFfc3BA237c4c231331b352c94Bb6bd1db0f1A21', '0x53a942ED3995B076453b301A3a08A16d492cCdB7', '0xB5B9c7dEB32b3cfb17b4cf5098436a5947a0bF7A', '0x298bfAF6c46243F020B2F84f581b476eEDAfF44c', '0x07e50B91C31016E01B52Cb1B4373bA463254e007', '0x95B585dF89F7B50a24124d0e1D4750c9282A535F', '0x21d8F3294c92e71be6B68CAe9b53d486Ebc8D0f1', '0xC2595Ce895e9697Ec951F784eCd85Bc961b55a6c', '0x925D335D1aaC048fcc839781f2Ace3bF58A4C2B3', '0x44252B1aC55ea4f5e45620483A3B9DE56a9F8FaC', '0x3a8aDA39FA4e8825A11a88ee1aFE8CD7b9fEc067', '0x8C44af51bAc9FefEf6d9584890AD1739DEB5658a',
  '0xb3B2Ad3a181cdb86fc847a8281F311a7bB9fb878', '0xe3729B25B5c6a35bc147376F9dE9f874284bb1BE', '0x7bcE751d46E62e289833B1C64Ba4D81a90b8b1e9', '0xD829954d68998d66679A4e80D567E4b553cC415c', '0x3A7685e64F0731F823F1426f05904EBE8E64cb4f', '0xF8Fffe4Bae8753B795f2de2779273fB5568D3251', '0x9388DcEF1a15a46375904d25F9002f01268f9629', '0xBA67b7D72CE83Db087FFa5e4fF5C9d27179989A7', '0xc1F2ccA7C0C5727d881Eac8F4b1D8eb5a200FBD4', '0x04905f777C47A73e8793EA112C6637393c23980e', '0x84664af67916af4BEe43038fB4D5d6042E4Dd8A2', '0x12Ff1886C1D79691D74910199E876e00DD9ebCaC', '0x490545ce76B4455cE5941012978035aF4cea745e', '0x3d0c07Ca869bACa8B2723bD085AAF982429B0B9e',
  '0x49af827f2344FF23c66484F88Fd9873eF44ca121', '0x4aBD9ae663e610874EDa60AF24D058ba74ddBeA4', '0x2949FC90cFAa52497dD2Fc92B4dA2BdA218522b6', '0xD9CB869829d6920D5d0101518Ef01d0c92BD0c4D', '0xcDaBe4960CB700816A9b3e287b10b7C9cE39a127', '0xBA3D450e933554B04D9a98151990919f2790D147', '0x29c45C8e54c95AcB47Ccb902544E8784f1451d14', '0x1ba87c4A69876D76D0Ca0b7b8C3b9E21Be60b2A7', '0x9a3358cB39BdE6aC2a6CF0A6f424fcF44C08d7D6', '0x04ad74572E814366f78600Ef65cDc037DFA97Be7', '0x3268fd426A08e4029fb28F823977338F7d151253', '0xB542851C9AE9c466425D460e437419D3911dfdE5', '0xC476856b6352067117C8a4dC872b91f2202c7ec0', '0xc75cB130F2e417a54B2F0825e87bE3f87ad9141C', '0x532ca9bEb5c0c09a48E2e348D2E448d9c8cac44A', '0xA0099BbE28ab20762db39560fC3711a75788E59D', '0xaa21dd6a9783C81Eb4D3781731e0b2ad6542c0d5',
  '0x650395E445A0de5dee16ec47beFeDF1a4377826B', '0x3470112e867354299251d2D603F139b4cE11E812', '0xec2B436978fD162BaEE7C84C593605823712f9B4', '0x761A32d1700B67AE7D02DA12e2483d72C0b07579', '0xC21c30eEA0baCB78c41D6Dbd980592c372aC6225', '0x97406F8edBCA43fbD8D6BFb877d618D09fdCf292', '0x6c42F5bB0534d13F8f114DaB298eCa4fD0B5F5Be', '0x6b320BE16d66311bE8C59B70aDfE6Daf48f096E6', '0x7bec3EA217F0E71f9174c95eE24b0860fc4975d3', '0xaa8B1905bF2D786FC9f31CE8b2058CA7417D68b1', '0xbee4E23fb5d3d0dB8CACD548E6864EAC2A014224', '0x493169955D8E0149675DFEe5CDDBFD7c8a944000', '0x57cD6939294C6dB20DC988Ae08164ED63820f7E9', '0x66e5864944A6eecb2F897C5288CEA1fdA4C1A6AA', '0xD1d1F2C81A4e8d85Fc2bC0ae86bdD181dE3a1492', '0xe4F1d00A70990c7813B5D4b8BC5a93fad3A89f5E', '0x137878D2e1cA1739e3F584bDf43741a739Df3E7f', '0x97aFA40c3bfb0A7F1712a6AE63cCE07F4387b23C', '0x605cb1f3B80B4eb66484df50D71193B7A3fA95eE',
  '0x32c9C37CD26c8a67c7D4E177589EEBd6150306ea', '0x6FB4A494d15cF2366d411FC5CAfabd20c4b8eba5', '0x50707b6D5746542b16AF705E451EC0EdECb51483', '0xE2cF79901EEDe7edBB22d1583c8125C77ECc038B', '0x693803304B1Bd626115A837bBF19fD2D6d0A4e6D', '0x2f22Ad5551e385aBF25a04a5f08125a36bc6C03F', '0x36eFB7C5E3A6414a84388313702242187239dfd8', '0x4672F7234bDeA92f4B0684ba62ed3bD6f8CD2B04', '0xB9Ca911a56F71a956880709beE7c396570Cc2FA9', '0x6033b47la9cd07A335D508cc691A4F02c9AF3c68', '0xE1Df9DCEFE3831D7cCfD77B4B9eADe9501f890Ff', '0xf0821eCB8BCf4F634e6C5142a2256197276F4904', '0x1018C3B025BA8bE3267707D3a28252C12b85b9b0', '0x4578cA8fb24EF226c7750E0fAE5155cAAf14c683', '0x6257276AEb97FCc430276f137AC6fb3072F4498F', '0x6cE5f628916805979B8d82f8460ADC184EEe1661', '0x0722fF53a1c0eE52758D9239360c28AB6fDC0e1F', '0x77CE15b00fFea1212D05cA6Fbe23f462EFF65C10', '0x03dC9d617d799Be939499B3bC7099aB21eC5Cc82',
  '0xBE55faAb5191FA953043Cfa02b31d614880C5344', '0x71a65153fFDa9cf68220F9a91e1fa234D36e8a58', '0x6fA23869A8437A1Eb95C27fFb4274827CAB535f6', '0xd5c2d839d9cA7bDC8bE39bEAbf727579408627B5', '0xc460ac36DD1b5017A5F8F9D04BF36A725335d1F5', '0xAbF045802B3ACFFfFF51A3110e77383a03acCb1E', '0x2c64b93726CE67fEb4d29e124A8605F734A5e934', '0x4f5E3a368B91B7C99c31fA2856abd61FA9052E6a', '0x638C15FF7863ca80b9099Ab7aC24A28532338FA3', '0x5d81045B7Ff54E7ad439B7917F86E8e1F0f19dFe', '0xEAe9644979dba846E13895EE115A248feEC7c137', '0xf4974E721026C047C741a54a2ff515ecF64d33De', '0xFf40Bb54310E81468387186EBD84e6a63dEa7A5c', '0x3899e0b2299635048b12846bcc674d16b7a6216a', '0xb4b3592491b3Eed554b24a16feb3B6c9C467161c', '0x6ff36AaC6bD9740d9444D7dfFD6c540B7D03Bc1B', '0xda6229D460Ae1705428e2c022d550f0318B19673', '0x9Ae291419eA2d6Db8B16AEbe1De244ee9C5EB7A0', '0x9dc856B3646e4daA540d6C6ABF646F96DAe3fB8A',
  '0x4E71174AaF47c44C9c79Accce54b6e74154BddfD', '0x9B21541D181609Ff01889dccB9562cF354e497e6', '0xe815e46Dbc1FFaAC3B8d7af9A552677eFfa930E9', '0xE3b4DD015537Ef623b0221ED722449e12BA37FD7', '0x5D1591AAd62e4f68360F5AD821f4307eDa2fCb3D', '0xF00590122A1C178f8dB214Bb18D66CE999Ef80a4', '0x1007A98c237cB923D0B68f7E16522C353191d1f6', '0x855832E8CAd78aDBEde0BDe3FB8901e7F9DdBB6A', '0x3199ED840E451b3420F4087BC51E918745C5Ed92', '0x1eC5723a9b029E1B7FEeFCa02d781cAA58776d8B', '0x5e44b670B86Afe84e0F656DaCf826d100a75273d', '0x8419fe961674C67679BD224f26e925093208ce60', '0x2753355679773B53C0f2598526c9636053718C18', '0x0671156567D8Ff07326cD7B024373fF2094e8c75', '0x06F664E468f1d4aCfFDC6774FA6f2632bb9E662A', '0x5a706513afa5143fB6Ca3e500c591862Fa972F18', '0x03B65626222Aca34512D43968a2EB0279e43C0FB', '0x592757d173190123bA6a6657068001946eA8B2Ef', '0x0AbE3F716Bdb2564Eb0C4dEE05557B02d77C9B60',
  '0xbC736715D9688Bec7d3Fc6DFE6309dEceb22C5e1', '0xaA013ba8e732b7324543322D16A5793a914f9E7f', '0x81208D87db9A7eC9BF6b24D74989485bcC3aAC81', '0x53e32B58EcDCDD49195aA9E950e6733Ff80D83f8', '0xe5Df55B166f417f59B567eB5232cCA1435223eEC', '0x89a2ee168f89ADC3b06907682676ef230a57CDB3', '0xd001f932100EA72Bed38182c5812763B9B0b03D1', '0xeddc319C7CFC4E00C8C5F5Cccb362534c7460e72', '0x6eb102c29c9e843cb6bebd6c53f95faf6533160d', '0xe6f3b3f434aeB99849c7d3c41eC94e48790f0384', '0x1F443E7B4AE51b4052DF0a8e59e408e4bA02bEfE', '0x70b26A9c631bFcBd5D1919581b538B48f223fB3E', '0xb4bc59D7d1F773C50720210bf67B27fE3EbD3A5d', '0xf3923bEC7cD34e05494e77F7Ed6c82e2daEd11C7', '0x0308E74D3b8a12A135AB509b7E8E15CFA38bdf50', '0xd6a65230D5ad74CBF1c5eE5DfCEd9F5E6d034938', '0xbd71e4e138aa7e5914d16b7df77195442e054ada', '0x995e62a3bA279787B6Ad431cC224Dd998E013479', '0xdbeF92b252e67d3CAfDED73e7bd02df8c2Fa3168',
  '0x2A7903a81C3540Fc77F31D3e918e43A28F6c3a3E', '0x89D89f183E113aB98d37791F8Dae2Dc6C29904F4', '0x3013E44C134fa3AF083Cf0ed5498f52a35e61858', '0x5659A24B86398F03AA36D6358868Ebdad1A5A2B2', '0xa690007bbaad3c458a34be4acb1de42ccdc6ff56', '0x6864ed83C92c4baA545124b2CB1d140C2c064F7D', '0x45568b7a4A19c2D3A47D650F6aEb8614e7674ACC', '0xEC53E9339C73c056A6Feb1988bc06237643cacA7', '0xA2425880B00A2AB60E7fF64B5d2bBfbe67d02C09', '0xaA013ba8e732b7324543322D16A5793a914f9E7f', '0xDcFD871740F830bCA00846E02E708E0d63BFd46a', '0xDBe3Cd76Ef2B79CdD3CCCb50758390cC4eC24975', '0x3Ebf602abAEE784B751168600abD86eD491D15b3', '0x0577a43F929923eAB2Cb40098E5f1E4B7FF61B55', '0x083ee831aFF0b77306663b0a7D389049F13dB093', '0x38AB5CF56666EA24529f6A29Ac83A8F1563D8920', '0x615aF40045a2A07b413FC3082b969E8CB5A6c157', '0x2C7f88348a7DCCF10c20E59fE5cf8Bb99Fd19b26', '0x251E3FE94f55ebc7D9230c8807b970A61bEfa57C',
  '0xee36185884f9620fe61e8bd97472e394a84230af', '0x98734d06e2cb0b1c03bf2d8423e2f5f4d5d83721', '0x81208D87db9A7eC9BF6b24D74989485bcC3aAC81', '0x97A5eE3ff560f06eDc2B88c51dCE92C45d809897', '0x80262415d1fE3f4634699c4205C929B23aBD35B2', '0x2CBCf267094af69384Ee9D9e881BC50Af433CFc8', '0x89F38793988B21A6C0F01Fd6623CbDB76500e711', '0x17ed372F23d1868F7a97a043b4b9cF9a42B1Acfa', '0xcf10fe1ad2eee7ce5f83767c8b0c77df5857b64d', '0xDA554ddD68006A7f14bbdB82bC85c7E794B67C6c', '0x8592086ea61a1D4E6aCAbbaFbBF8413CC7718A23', '0xE0B31beF33012801848a04288Ff76fd63b07d489', '0x7fa33a08b287B403b03BAe173fB7976F48dAABE9', '0xa3D570eC63F87912F4F950c702421702341E015c', '0x704171a8B46A006C9890Fa8d9683153ab35F62d1', '0x12e12dFffe4A1069d972f6AC1c41928F7d54Ffd8', '0x297Fe0f92b2F59bbc475f1d43230Cb7bf823fAAF', '0x687Fc49CD772A2B53AFe090830ED6Fc74c9B8E8B', '0x49b729eC3D6DE397a16D94fA4ed919Aa0e8aD9ce',
  '0x9b9957A00282c5A06708Dc7432799fFe148611b0', '0xF32ADb96F4eBa3f357343F44C886c7B65ee49aC8', '0x879e319EF086b081B5C516718BE7c1b12eC6DA40', '0x2F4DafEEEc8159677A3C31b74a93d915AF8d3E75', '0xdd7F7E28349A9BfC35FA2C9fA487613E4d8Db0b8', '0xd2801198eF9C46ba37A06Ea43519C71fBbEF3319', '0x70b26A9c631bFcBd5D1919581b538B48f223fB3E', '0x2408D3E0cCdF6b5fd9e85D2975C89fdd6D6aE24f', '0xe6aF771277FefDc52D0d11609A3d9844DAD4a629', '0xEb33ca12754cE30ADC16a56a99d6713d1B1bf4b0', '0xcfa927dad430666527acc59386f55201d74c3f30', '0x01b424B9A90a6C31fc0919d76D5b2e196D06c40b', '0x6e47cB67353854d2289273033C9834f5f1DE6Ca9', '0x2A230D06E62Ae71CeaA2542e869cfd68621681C6', '0xfda3337e3b0b82A41A48378c0c54d51dc5e2D1e9', '0x882f8582E2EA24f16fE9f90d555903a8De85b15F', '0xa3b1756cd3005795e3B1D64DeE96127B54cB8C32', '0x7a7307119D576f812E43F4De940eE33Fc1645a2C', '0x9b945A1eB0F09011A6aE6B2aE14c680547ABe494',
  '0x3Cdd7e90636f911432491233A1e823A9afb0A93b', '0x62756BEdC2c98f80cE83E45BC9f8062345b65d8e', '0x3a5D0b32AD798C53F6AB38bBc597CaF41c3Ca23f', '0x8aAF5Be642c5e5CEb73F20cbF919A4bA660a0272', '0xdBa5B042e602D40e9385e6Efcf3d3FDccDFBba62', '0xc8e1828E31508ff6af625fe5B393Fcbf56a24927', '0x2D6C902B6C0B0c92dB182670b76a7d2a041B346d', '0x6eB710953aE1a0248f5899b78B1a2cA663B4B217', '0x1eb174AE7a52104385AF3b0bd82E0F0dB92EA18C', '0x8230b13E37a3a8EE5BD24Ca399Ed8db0Dd397a59', '0x6376bA69AdE23D5aB1e307250Ff277D54163d2D6', '0x149c4c6B9fb968Dcc3360519F96A91875f27ac73', '0x41F1cd777AC6F61e23f77878A84bDD5EC095338C', '0xc250F0F30C4a8e681e0b489af1D9858b7724B24a', '0x0CC386301e0D808a10EB0e4203E3780d6b95a9B0', '0x3CBD3d635eBfc6dc3Dc3e3A71e2B36123B66E891', '0x1f5Be9B215D91cB9dF6aa971f546d1eA5551097d', '0x338313F71E65a4F6528415C91Cc420fF80d12d1A', '0x3269da4A989b5aA3C3bccc8Ec625B128D0f493D7',
  '0x7990bD07f6AFA5004bb1ea3e09e4DBEA32a76cda', '0x2C7d4EDF2F1D3b717393c3Bce3a64EB41e060734', '0xf1A8850459AB05459F965093BB6DbfF616bce825', '0x628f68eC1c500E3139EB10E96bA9aa5F1059431e', '0xF9f5D3c361b736D7B82e0A59EDb22BfB2015Cf68', '0x6491C041b437ac153b976Ed7DC2933dB447D0413', '0x899defdb453fDa896A86221813004217F20Cb7A5', '0xA4F700Ed34cD5FFf1Ac28dCd9E35c7D9AF680770', '0x6C19B30C28F6Da3cCA364749726348d300D3CcD6', '0x472f24CF9c2d6cbce141B5A466F4fff1fb8222a2', '0x3c91058A0A63428620F1b37bC6566416ac618269', '0x3AF3316a1612b40596639f7494a4C90Bfe14a8bB', '0x1A3dc81950Ef91661B2f2A1C48eE261B6cb58a91', '0x131a99dbc65A3Ed7a4d85f4e1E90Ea6140978750', '0x7381f27Ced3Fc6C9d1876B1dA7901f0C516C9B78', '0xb86514fE0D27aEd880cfC36AcB66A3B27A2d5126', '0x87E83C21AFFe8688febe60e466b995D4dc1d9543', '0x941cF141942117C8EEa7bf3aD258EA96D68442c1', '0x89C11B7FB25954157a04A1Ad95C0c8a277543F03',
  '0x78D04A9dB0A3ecD740C323f654eCEA78614a64b4', '0x4224c955bdc6B87d60F2eF4718c6c5022edb09E0', '0x99FbF035be64AD99F8EF4Bc4Bf272198594374b5', '0xFaC34fabE0109F15F78cfA33D8cdb39960286995', '0xFfFba8D8DD8733e3e1DfA406a642DB013A72046C', '0x7E2A384c93C31E00BD8a2156bEf419f8076A5F74', '0x6B12481781547b44BACe7E0250a372F03eC55328', '0x01A762639eD27CF925F3F9b08Afd7C537b1d4D81', '0x95925033ae066B5509EB4F4C02Ad8E81ddB75f91', '0xF0419D23a6D9aa44d6351EB47BaF4A4008E2EFFF', '0x54030d3750937eDda7fE62701e89e29CDD1E88D4', '0xeA69EE130849B819bB9165CA52eaFB65C4393aB3', '0x5a61bC171CEaA39cB84d00bEE28a5806d0165ec2', '0x378C81C783db5333A62c89888365a375e852B0c2', '0x0A3Cb94214613F715eD7e13ddA57Ac5b36D767fFp', '0x4E71174AaF47c44C9c79Accce54b6e74154BddfD', '0x5b52D3D7551a010D39b7438194EA7faFba56eF7D', '0xdF4bB00567CDE63BDE035E8BB7c17790F77aBeD4', '0xc209e3aa8F1ecee3985fb816B4322cE9f7699af2',
  '0x53f0ED8305ACCdDE297c3217AF2751Eb4ba367B2', '0xbc7C3c7222da2A25318b827df40C1AE62362e58a', '0x1e82A5E3Ab1bBE7420E24eC1A8AA8edeEB34966C', '0xcC922848eDA8952dC860394131B5C9159538Be6E', '0x95674fF4976205e2C39d2E5090B0bE3748EaB347', '0x1351589d33bE6f667987A79EACce8BE0977D81b9', '0xb537CD665E2a4cFCB7b44de80055DBEeF068b768', '0x0AceBed7719793ceb7B5ec46393e341bD91217DA', '0xc9930346f402d918Ee178D359C9df2DcF3f0B617', '0x968C5BEB16A3F627F32c90e814E1E80d8F586d6a', '0x5e5399161eDA8b22e68715d37d0C5e403a769191', '0x95674fF4976205e2C39d2E5090B0bE3748EaB347', '0x1351589d33bE6f667987A79EACce8BE0977D81b9', '0xb537CD665E2a4cFCB7b44de80055DBEeF068b768', '0x0AceBed7719793ceb7B5ec46393e341bD91217DA', '0xcC922848eDA8952dC860394131B5C9159538Be6E',
];

const leafNodes = addrs.map((addr) => keccak256(addr));
const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });

const leafNodes2 = addrs2.map((addr) => keccak256(addr));
const merkleTree2 = new MerkleTree(leafNodes2, keccak256, { sortPairs: true });

// console.log(merkleTree.toString());

const mintbutton = (props) => {
  const [count, setCount] = useState(1);
  const [message, setMessage] = useState('Our OG mint is now closed.');
  const [saleState, setSS] = useState(1);
  const [remaining, setRemaining] = useState(10000);
  const [early, setEarly] = useState(false);
  const [whitelistnum, setWhitelistnum] = useState(0);
  const [contract, setContract] = useState(null);

  useEffect(() => {
    // Update the document title using the browser AP
    if (addrs.includes(props.address)) {
      setWhitelistnum(1);
      setMessage('You are on the OG whitelist. You may mint up to 3 NFTs during the presale at a price of .06 each. If you plan on minting multiple, minting the NFTs in a single transaction will result in least amount of gas');
      setEarly(true);
    } else if (addrs2.includes(props.address)) {
      setWhitelistnum(2);
      setMessage('You are on the whitelist. You may mint up to 2 NFTs during the presale at a price of .06 each. If you plan on minting multiple, minting the NFTs in a single transaction will result in least amount of gas');
      setEarly(true);
    }

    try {
      const contract1 = new ethers.Contract(
        CONTRACT_ADDRESS,
        abi.abi,
        props.web3Provider.getSigner(),
      );
      setContract(contract1);
      if (saleState === 2) {
        setEarly(true);
        setMessage('Public sale is live! You may mint up to 10 NFTs per transaction.');
      }
    } catch (err) {
      setMessage('Our OG mint is now closed.');
      console.error('Error initializing contract');
    }
    if (props.address) {
      if (saleState === 2) {
        setEarly(true);
        setMessage('Public sale is live! You may mint up to 10 NFTs per transaction.');
      }
    } else {
      setEarly(false);
    }
  }, [props.address, props.web3Provider]);

  useEffect(async () => {
    while (true) {
      try {
        const info = await axios.get(`${BD_API_URL}/metahouseMafiaContractState${IS_PROD ? '' : '?testnet=1'}`);
        const sale = parseInt(info.data.saleState);
        const totalMinted = parseInt(info.data.numMinted);
        setRemaining(totalMinted);
        setSS(sale);
      } catch (error) {
        console.log(error);
      }
      await delay(10 * 1000);
    }
  }, []);

  const mint = async () => {
    const helperScale = 1000000;
    let singlePriceWei, estimatedGas, hexProof;

    if (saleState === 1) {
      singlePriceWei = ethers.BigNumber.from(WEI_PER_ETH).mul(Math.round(WEI_PER_TOK_EA * helperScale)).div(helperScale);
    } else {
      singlePriceWei = ethers.BigNumber.from(WEI_PER_ETH).mul(Math.round(WEI_PER_TOK_REG * helperScale)).div(helperScale);
    }

    const priceWei = singlePriceWei.mul(count);
    const baboo = keccak256(props.address);
    if (whitelistnum === 1) {
      hexProof = merkleTree.getHexProof(baboo);
    } else {
      hexProof = merkleTree2.getHexProof(baboo);
    }

    try {
      estimatedGas = await contract.estimateGas.mint(count, hexProof, whitelistnum, { value: priceWei });
    } catch (err) {
      console.log(err);
      alert('Error minting... Do you have enough funds in your wallet to cover the minting and gas fee?');
      return;
    }
    // console.log(estimatedGas);
    const gasLimit = estimatedGas.add(50000); // Add 50,000 to prevent failures from bad gas limit estimate.
    // eslint-disable-next-line one-var
    try {
      await contract.mint(count, hexProof, whitelistnum, { value: priceWei, gasLimit });
    } catch (err) {
      alert('There was an issue minting.');
      return;
    }
    setMessage('Thanks for minting!');
  };

  const incrementCount = () => {
    if (saleState === 2 && count < 10) {
      setCount((count + 1));
    } else if (saleState === 1 && count <= 2 && whitelistnum === 1) {
      setCount((count + 1));
    } else if (saleState === 1 && count <= 1 && whitelistnum === 2) {
      setCount((count + 1));
    }
  };

  const decrementCount = () => {
    if (count > 1) {
      setCount((count - 1));
    }
  };

  const renderer = ({
    days, hours, minutes, seconds,
  }) => {
    return (
      <div id="W-count">
        <div className="W-timebox">
          <div className="W-time">{zeroPad(days)}</div>
          <div className="W-timetype">DAYS</div>
        </div>
        <div className="W-timebox">
          <div className="W-time">{zeroPad(hours)}</div>
          <div className="W-timetype">HOURS</div>
        </div>
        <div className="W-timebox">
          <div className="W-time">{zeroPad(minutes)}</div>
          <div className="W-timetype">MINUTES</div>
        </div>
        <div className="W-timebox">
          <div className="W-time">{zeroPad(seconds)}</div>
          <div className="W-timetype">SECONDS</div>
        </div>
      </div>
    );
  };

  return (
    <div id="mintwrapper">
      <div id="title1">THE META</div>
      <div id="title2">HOUSE MAFIA</div>
      <div id="line" />
      <div id="mintmessage">{message}</div>
      <Countdown date={new Date(1644700800000)} renderer={renderer} />
      <div id="mint-buttons">
        <div id="count-buttons">
          <div id="minus" onClick={decrementCount}>
            <div id="leftbullet" />
          </div>
          <div id="countbox">{count}</div>
          <div id="plus" onClick={incrementCount}>
            <div id="rightbullet" />
          </div>
        </div>
        {(early) ? (
          <div role="button" id="mintreal" tabIndex={-1} onClick={mint} className="mintbutton">
            MINT NOW
          </div>
        ) : (
          <div id="fakemint" className="mintbutton">
            MINT NOW
          </div>
        )}
      </div>
      <div id="mintcount">{remaining}/10,000 MINTED</div>
    </div>
  );
};

const mapStateToProps = (state) => (
  {
    provider: state.web3.provider,
    web3Provider: state.web3.web3Provider,
    address: state.web3.address,
    chainId: state.web3.chainId,
  }
);

export default connect(mapStateToProps, null)(mintbutton);
